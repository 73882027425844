import RightArrowIcon from "../images/icons/right-arrow.svg";
import ArrowLeft from "../images/icons/arrow-left.svg";
import ArrowRight from "../images/icons/arrow-right.svg";
import CheckedIcon from "../images/icons/checked.svg";
import PlusIcon from "../images/icons/plus.svg";

const ICONS = {
  RightArrow: RightArrowIcon,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  CheckedIcon,
  PlusIcon,
};

export default ICONS;

import React from "react";
import Slider from "react-slick";
import DataIcon from "../../images/icons/data.svg";
import BannerContainer from "../common/BannerContainer";

export default function WhatWeDoContainer() {
  const servicesList = [
    {
      name: "Simple Opex Model",
      icon: "https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fwhat_we_do%2Ficon-opex.svg?alt=media&token=5846c0c5-a63f-4d43-9365-032a1587b9eb",
    },
    {
      name: "Unlimited Customisations & Enhancements",
      icon: "https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fwhat_we_do%2Ficon-customization.svg?alt=media&token=49c57de6-4578-46f3-ab30-ddd1bdb242da",
    },
    {
      name: "24x7 Support for Critical Issues",
      icon: "https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fwhat_we_do%2Ficon-support.svg?alt=media&token=c125f77c-8f45-467c-867c-f4717b4d70d2",
    },
    {
      name: "Fully-Stacked Data Team",
      icon: "https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fwhat_we_do%2Ficon-datateam.svg?alt=media&token=7f232926-6346-4754-80d3-ae43416b005b",
    },
    {
      name: "Cloud Acceleration",
      icon: "https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fwhat_we_do%2Ficon-cloud-acceleration.svg?alt=media&token=79acf813-990d-45c3-88fc-48fed37d4122",
    },
  ];
  const settings = {
    speed: 1200,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <section className="flex-1 overflow-hidden">
      <div id="service" className="flex w-full flex-col">
        <BannerContainer
          title="what we do"
          heading="We bring predictability and<br /> effectiveness to data analytics"
        />
        <div className="pl-0 md:pl-24 mt-6">
          <Slider {...settings}>
            {servicesList.map((service, i) => (
              <div key={`wwd-${i}`} id="driving-brands-slider">
                <div className="p-10 h-[28em] flex flex-col text-white bg-[#1938A8] hover:bg-white hover:text-black">
                  <div className="flex-1 text-3xl">{service.name}</div>
                  <div className>
                    <img src={service.icon} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

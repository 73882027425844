import React from "react";
import Footer from "../components/Footer";
import GetAQuote from "../components/home/GetAQuote";
import Testimonials from "../components/home/Testimonials";
import TopbarDark from "../components/TopbarDark";
import ConsultingServices from "../components/what_we_do/ConsultingServices";
import Results from "../components/what_we_do/Results";
import WhatWeDoContainer from "../components/what_we_do/WhatWeDo";

export default function WhatWeDo() {
  return (
    <main>
      <TopbarDark />
      <WhatWeDoContainer />
      <ConsultingServices />
      <Testimonials />
      <Results />
      <GetAQuote />
      <Footer />
    </main>
  );
}

import { Button } from "@mui/material";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";

export default function ConsultingServices() {
  let services = [
    {
      title: 'Analytics <br class="hidden md:block" /> as a Service',
      route: "/analytics_as_a_service",
      narration:
        "We operate on a fixed per-month fee for all your data analytics need - unlimited reports, modifications and data storage. The entire engagement is localized to your needs - no mass market, no 'templates', no generalization.",
    },
    {
      title: 'Data Team <br class="hidden md:block" /> as a Service',
      route: "/analytics_as_a_service",
      narration:
        "Because we have our skin in the game with a fixed-fee model, you are assured of timely, relevant, and high quality analytics. With us, you get business partners and not vendors. No wild, expensive and wasteful recommendations from us!",
    },
    {
      title: 'Technology <br class="hidden md:block" /> as a Service',
      route: "/analytics_as_a_service",
      narration:
        "Our fixed-fee Opex model is a no brainer. No time sheets, scope or change management, and no need for you to have a dedicated team to approve complex documents. And you get to talk to real humans, supported by Slack®",
    },
  ];
  return (
    <section className="flex flex-col items-center">
      <div className="mt-12 md:p-8 font-semibold">OUR CONSULTING SERVICES</div>
      <div className="px-6">
        {services.map((service) => (
          <div className="py-8 w-full">
            <div className="pb-12 flex flex-col md:flex-row items-center">
              <div className="title text-2xl md:text-4xl self-start md:self-center">
                <div dangerouslySetInnerHTML={{ __html: service.title }} />
              </div>
              <div className="flex-1 mt-4 md:mt-0 md:px-32 flex justify-center">
                We operate on a fixed per-month fee for all your data analytics
                need - <br className="hidden md:block" />
                unlimited reports, modifications and data storage. The entire
                engagement is <br className="hidden md:block" />
                localized to your needs - no mass market, no 'templates', no
                generalization.
              </div>

              <AniLink fade to={service.route} className="text-right">
                <button
                  variant="contained"
                  className="rounded-full bg-[#57B847] px-12 py-3 text-white mt-12 md:mt-0"
                >
                  Learn More
                </button>
              </AniLink>
            </div>
            <hr />
          </div>
        ))}

        {/* <div className="md:py-8">
          <div className="mb-12 flex flex-col md:flex-row items-start md:items-center">
            <div className="title text-2xl md:text-4xl flex-1">
              Data Team <br className="hidden md:block" />
              as a Service
            </div>
            <div className="flex-1 mt-4 md:mt-0">
              Local issues, targeted outcomes, competition, regulations – all
              drive different analytic needs. Which themselves change over time.
              The “business domain” is dynamic. And we are too – agile,
              responsive and fast.
            </div>
            <div className="text-right">
              <Button
                variant="contained"
                href="analytics.html"
                className="rounded-full bg-[#57B847] px-12 py-3 text-white"
              >
                Learn More
              </Button>
            </div>
          </div>
          <hr />
        </div>
        <div className="py-4 md:py-8  pb-16">
          <div className="mb-12 flex flex-col md:flex-row items-start md:items-center">
            <div className="title text-2xl md:text-4xl flex-1">
              Cloud <br className="hidden md:block" />
              Acceleration
            </div>
            <div className="flex-1 mt-4 md:mt-0">
              Our fixed-fee Opex model is a no-brainer. No time-sheets, scope
              &amp; change management and massive oversight expenses to manage
              vendor bills. And you get to talk to real humans at ThinkLokal,
              supported by simple tools like Slack®.
            </div>
            <div className="flex-1 text-right mt-12">
              <a
                href="#"
                className="rounded-full bg-[#57B847] px-12 py-3 text-white"
              >
                Learn More
              </a>
            </div>
          </div>
          <hr />
        </div> */}
      </div>
    </section>
  );
}

/*
let services = [
    {
      title: 'Analytical <br class="hidden md:block" /> the past',
      text: "We bring our technical and consulting experience on past operational and environmental data analytics. Typically the bulk of 'Data Analytics', we setup models, dashboards, reports and self-help ad-hoc reporting tools to help you understand what your data is telling you.",
    },
    {
      title: 'Operational <br class="hidden md:block" /> the present',
      text:"We bring our technical and consulting experience on past operational and environmental data analytics. Typically the bulk of 'Data Analytics', we setup models, dashboards, reports and self-help ad-hoc reporting tools to help you understand what your data is telling you.",
    },
    {
      title: 'Predictive <br class="hidden md:block" /> the future',
      text:"Depending on your organizational maturity and culture, our engagement can include the development and deployment of Machine Learning and Artificial Intelligence (AI/ML) for predictive analytics. In our experience many 'AI/ML needs' are met with our Analytical and Opertional services described above, leaving just a few edge-cases that truly need AI or ML.",
    },
  ];
  */
